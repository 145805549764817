.list-page{
    &__head{
        background: $bg-body;
        padding-top: 1rem;
        position: sticky;
        left: 0;
        strong.text-primary{
            word-break: break-all;
        }
    }
    &__title{
        color: $color-hover;
        font-size: 26px;
        font-weight: bold;
    }
    &__table{

        .mw-250{
            min-width: 250px;
        }
        thead{
            th,td{
                position: relative;
            }
            a{
                color: $color-main;
                display: block;
                padding: 0 10px;
                &:hover{
                    color: $color-hover;
                }
                &:after{
                    position: absolute;
                    right: 8px;
                    font-family: $font-awesome;
                    content: " \f0dc";
                    top: 50%;
                    @include translate(0, -50%);
                }
                &.asc:after {
                    content: " \f0d8";
                }
                &.desc:after {
                    content: " \f0d7";
                }

            }
        }
        tr{
            scroll-margin-top: 80px;
        }
    }
    &__bottom{
        padding: 0.5rem 0;
    }

    .table-scrollable{
        overflow-x: scroll;
        position: relative;
        .clone-head-table-wrap{
            padding-right: 6px;
            visibility: visible !important;
            @-moz-document url-prefix() {
                padding-right: 17px;
            }
        }

        thead{
            th{
                position: -webkit-sticky;
                position: sticky;
                top: 0;
                z-index: 100;
                background: #f8f9fa;
                border-top: 1px solid #dee2e6;
                border-bottom: 1px solid #dee2e6;
                @include box-shadow(1px 0px 0px 1px #dee2e6);
            }
        }
    }

    .table-info-responsive{
        th, td{
            &:not(:last-child){
                border-right: 1px solid #ddd;
            }
        }
        th{
            white-space: nowrap;
        }
        .confirm-before-change{
            word-break: break-all !important;
        }
        .confirm-after-change{
            word-break: break-all !important;
            word-wrap: break-word;
        }
        @media (max-width: 575px) {
            thead{
                display: none;
            }
            tbody, tr, th, td{
                display: block;
            }
            th, td{
                &:not(:last-child){
                    border-right: none;
                    padding-bottom: 0;
                }
            }
            .confirm-after-change{
                color: #dc3545;
            }
        }

    }
    .bg-col-search{
        background-color: #ffff00 !important;
        td{
            background-color: #ffff00 !important;
        }
    }
    .bg-result-search{
        background-color: #ff9632 !important;
    }
}

.alert.in{
    opacity: 1;
    @include border-radius(0)
}
.help-block{
    color: red;
}
.fixed-first-collumn
{
    position: -webkit-sticky;
    position:sticky;
    left:0px;
    background-color:white;
    width: 90px;
    z-index: 150;
    -webkit-box-shadow: 0 1px 0 1px #dee2e6;
    border: none!important;
    border-top: 1px solid #dee2e6 !important;

}
.fixed-second-collumn
{
    position: -webkit-sticky;
    position:sticky;
    left: 90px;
    background-color:white;
    z-index: 150;
    -webkit-box-shadow: 0 1px 0 1px #dee2e6;
    border-left: none!important;
    border-top: 1px solid #dee2e6 !important;
}

tr:last-child{
    .fixed-first-collumn,
    .fixed-second-collumn{
        box-shadow: -2px 0px 0 -1px #dee2e6, 2px 0px 0 -1px #dee2e6;
    }
}

@-moz-document url-prefix() {
    .fixed-second-collumn, .fixed-first-collumn {
        @include box-shadow(0 0 0 1px #dee2e6);

    }
    .table-scrollable thead th{
        @include box-shadow(0px 0px 0px 1px #dee2e6!important);
    }
    tr:last-child{
        .fixed-first-collumn,
        .fixed-second-collumn{
            @include box-shadow(0 0 0 1px #dee2e6);
        }
    }

}

.list-page .table-scrollable thead .fixed-first-collumn{
    left:0;
    z-index: 201;
    background-color:#f8f9fa;
    -webkit-box-shadow: 0px 0px 0px 1px #dee2e6;
    }
.list-page .table-scrollable thead .fixed-second-collumn{
    left:90px;
    z-index: 201;
    background-color:#f8f9fa;
    -webkit-box-shadow: 0px 0px 0px 1px #dee2e6;
}
.list-page .table-scrollable .table-hover tbody tr:hover td {
    background-color:silver;
}
.list-page .table-scrollable .table{
    table-layout: fixed;
    min-width: 100%;
}
.list-page .table-scrollable .textwrap{
    word-wrap: break-word;
    overflow-wrap: break-word;
}
.list-page .table-scrollable .table-hover tbody tr td {
    word-break: break-all;
}
.padding-10{
    padding: 0 10px;
}
.width-70{
    width:70px;
}
.width-90{
    width:90px;
}
.width-100{
    width:100px;
}
.width-110{
    width:110px;
}
.width-120{
    width:120px;
}
.width-130{
    width:130px;
}
.width-150{
    width:150px;
}
.width-170{
    width:170px;
}
.width-200{
    width:200px;
}
.width-220{
    width:220px;
}
.width-250{
    width:250px;
}
.width-300{
    width:300px;
}
.width-400{
    width:300px;
}
.width-500{
    width:500px;
}
.No{
    color: #4597f5;
    padding: 0 10px;
}




