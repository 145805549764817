.header {

    background: $color-main;
    position: sticky;
    left: 0;
    top: 0;
    z-index: 1000;
    //header-middle
    &__middle {
        background: $color-main;
        color: #fff;
        padding: 20px 0;
    }

    &__logo {
        font-size: 20px;
        font-weight: bold;
        color: #fff;
        margin: 10px 0;
        span {
            &:not(:last-child) {
                margin-right: 5px;
            }
        }
    }

    &__home {
        font-size: 24px;
        position: absolute;
        left: 0;
        top: 50%;
        @include translate(0, -50%);
    }

    &__hotline {
        position: absolute;
        right: 50px;
        top: 50%;
        @include transform(translateY(-50%) rotate(-45deg));
        font-size: 30px;
        display: none;
    }

    .small-auth {
        position: absolute;
        right: 0;
        top: 50%;
        z-index: 100;
        @include translate(0, -50%);

        &__logined {
            @include flexbox();
            @include align-items(center);

            img {
                width: 30px;
                height: 30px;
                @include border-radius(50%);
                border: 1px solid $color-main-dark;
                @include object-fit(cover);
            }

            span {
                display: inline-block;
                max-width: 150px;
                white-space: nowrap;
                -ms-text-overflow: ellipsis;
                text-overflow: ellipsis;
                overflow: hidden;

                &:not(:first-child) {
                    margin-left: 8px;
                }
            }
        }

        &-nav {
            &__head {
                margin-bottom: 10px;
            }

            &__avatar {
                width: 80px;
                height: 80px;
                display: block;
                margin: 0 auto 10px;
                border: 1px solid $color-main;
                @include border-radius(50%);
                @include object-fit(cover);
            }

            &__name {
                font-weight: bold;
                text-align: center;
                font-size: 16px;
                margin: 0;
            }

            &__body {
                ul {
                    padding: 0;
                    margin: 0;
                    list-style: none;

                    li {
                        &:not(:last-child) {
                            margin-bottom: 5px;
                        }

                        a {
                            display: block;
                            position: relative;
                            padding: 3px 0 3px 25px;

                            i {
                                position: absolute;
                                left: 0;
                                top: 5px;
                                color: $color-main;
                                @include transition(all linear .2s);
                            }

                            &:hover {
                                color: $color-main;

                                i {
                                    color: $color-hover;
                                }
                            }
                        }
                    }
                }
            }
        }

        &__dropdown {
            margin-top: 15px;
            background: $color-main-light;
            padding: 15px;
            width: 220px;

            &:before {
                content: '';
                @include triangle(top, 0 8px 10px 8px, $color-main-light);
                position: absolute;
                top: -10px;
                right: 5px;
            }
        }
    }


    //header-bottom
    &__bottom {
        background: #f7f7f7;
    }

    @import "../components/header-nav";

    @media (max-width: 1199px) {
        &__home {
            left: 35px;
        }
    }
    @media (max-width: 991px) {
        &__hotline {
            display: block;
        }
    }
    @media (max-width: 767px) {
        &__middle {
            padding: 15px 0;
        }
        &__logo {
            font-size: 18px;
        }
    }
    @media (max-width: 575px) {
        &__middle {
            padding: 5px 0;
        }
        &__logo {
            span {
                display: block;
            }
        }
    }
    @media (max-width: 350px) {
        &__logo {
            font-size: 16px;
        }
    }
}
