.login-page {
    min-height: 100vh;
    padding: 50px 0;
    @include flexbox();
    @include align-items(center);
    background: $color-main-light;
    background-size: 300% 300%;
    animation: bg-gradient 20s ease infinite;

    &__logo {
        font-size: 25px;
        color: $color-hover;
        font-weight: bold;
        margin-bottom: 20px;
        text-align: center;
    }

    .form-login {
        max-width: 550px;
        width: 100%;
        margin: 0 auto;
        background: $color-main;
        padding: 30px;
        position: relative;
        color: #fff;
        &:before {
            content: '';
            position: absolute;
            top: 10px;
            left: 10px;
            right: 10px;
            bottom: 10px;
            border: 1px solid rgba(#fff, 0.5);
            z-index: 0;
        }

        form {
            position: relative;
        }

        &__title {
            text-align: center;
            margin: 0 0 25px;
            font-size: 26px;
            color: #fff;
            text-shadow: 2px 2px 3px $color-hover;
        }

        .form-group {
            label {
                margin-bottom: 5px;
                font-size: 18px;
            }
        }

        .form-control {
            height: 50px;
            background: rgba(#fff, 0.5);
            border: none;
            font-size: 18px;
            @include border-radius(0);
            @include transition(all linear .2s);

            &:focus {
                background: rgba(#fff, 0.8);
            }
        }

        .btn-login {
            background: rgba($color-hover, 0.5);
            height: 50px;
            width: 100%;
            margin-top: 15px;
            @include border-radius(0);
            font-size: 20px;
            color: #fff;

            &:hover {
                background: $color-hover;
            }
        }

        .alert.in {
            opacity: 1;
            @include border-radius(0)
        }
    }

    .error[role="alert"]{
        color: #856404;
        background-color: #fff3cd;
        position: relative;
        padding: .75rem 1.25rem;
        margin-bottom: 1rem;
        border: 1px solid transparent;
    }
}

