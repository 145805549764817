.form-filter{
  background: #fff;
  padding: 5px;
  @include box-shadow(0 0 22px -18px #000);

  .row{
    margin: -5px;
  }
  [class*="col-"]{
    padding: 5px;
  }
  .form-control{
    min-height: 30px;
  }
  .btn{
    width: 100%;
  }
}