.paginator {
    .pagination {
        @include align-items(center);
        @include flex-wrap(wrap);
        margin: 0;

        li {
            margin: 2px;

            &.active {
                span {
                    color: $color-main;
                    font-weight: bold;
                    padding: 5px;
                    white-space: nowrap;
                }
            }

            a {
                border: 1px solid #eee;
                background: #fff;
                padding: 5px;
                min-width: 30px;
                display: block;
                text-align: center;
                white-space: nowrap;

                &[href]:hover {
                    color: $color-hover;
                    background: $color-main-light;
                    border-color: $color-main;
                }
            }
        }
    }
}
