html, body {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 14px;
    line-height: 1.5;
    font-family: $font-main;
    color: $color-text;
    position: relative;
    background: $bg-body;
}

img {
    max-width: 100%;
}

a {
    color: inherit;
    @include transition(all linear .3s);

    &:hover, &:focus {
        color: inherit;
        text-decoration: none;
        outline: none;
    }
}

button, input, select {
    @include transition(all linear .2s);

    &:focus {
        outline: none;
    }
}

input,
select,
textarea {
    border-color: #eaeaea;

    &:hover,
    &:focus {
        outline-color: $color-main;
    }
}

input, textarea {
    &:-webkit-autofill,
    &:focus:-webkit-autofill {
        @include box-shadow(0 0 0 50px #e0fffd inset);
        -webkit-text-fill-color: #333;
    }

    &::-ms-clear, &::-ms-reveal {
        display: none;
    }
}

h1 {
    font-size: 2.5em;
}

h2 {
    font-size: 2em;
}

h3 {
    font-size: 1.5em;
}

h4 {
    font-size: 1.25em;
}

h5 {
    font-size: 1.125em;
}

h6 {
    font-size: 1em;
}

/* scrollbar */
::-webkit-scrollbar {
    width: 15px;
    height: 15px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: #bbb;
    @include border-radius(10px);
}

::-webkit-scrollbar-thumb:hover {
    background: $color-main;
}

.alert-brand{
    display: none;
}
