//box-shadow
@mixin box-shadow($shadow){
  -moz-box-shadow: $shadow;
  -o-box-shadow: $shadow;
  -webkit-box-shadow: $shadow;
  box-shadow: $shadow;
}

//background-size
@mixin background-size($background-size){
  -moz-background-size: $background-size;
  -o-background-size: $background-size;
  -webkit-background-size: $background-size;
  background-size: $background-size;
}

//user select
@mixin user-select($select){
  -webkit-user-select: $select;
  -moz-user-select: $select;
  -ms-user-select: $select;
  -o-user-select: $select;
  user-select: $select;
}

//border-radius
@mixin border-radius($border-radius){
  -moz-border-radius: $border-radius;
  -webkit-border-radius: $border-radius;
  border-radius: $border-radius;
}

//object-fit
@mixin object-fit($obj){
  object-fit: $obj;
  -o-object-fit: $obj;
}

//flexbox
@mixin flexbox() {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
@mixin flex($values) {
  -webkit-box-flex: $values;
  -moz-box-flex: $values;
  -webkit-flex: $values;
  -ms-flex: $values;
  flex: $values;
}
@mixin flex-direction($direction) {
  -webkit-flex-direction: $direction;
  -moz-flex-direction: $direction;
  -ms-flex-direction: $direction;
  flex-direction: $direction;
}
@mixin flex-wrap($wrap) {
  -webkit-flex-wrap: $wrap;
  -moz-flex-wrap: $wrap;
  -ms-flex-wrap: $wrap;
  flex-wrap: $wrap;
}
@mixin flex-flow($flow) {
  -webkit-flex-flow: $flow;
  -moz-flex-flow: $flow;
  -ms-flex-flow: $flow;
  flex-flow: $flow;
}
@mixin order($val) {
  -webkit-box-ordinal-group: $val;
  -moz-box-ordinal-group: $val;
  -ms-flex-order: $val;
  -webkit-order: $val;
  order: $val;
}
@mixin flex-grow($grow) {
  -webkit-flex-grow: $grow;
  -moz-flex-grow: $grow;
  -ms-flex-grow: $grow;
  flex-grow: $grow;
}
@mixin flex-shrink($shrink) {
  -webkit-flex-shrink: $shrink;
  -moz-flex-shrink: $shrink;
  -ms-flex-shrink: $shrink;
  flex-shrink: $shrink;
}
@mixin flex-basis($width) {
  -webkit-flex-basis: $width;
  -moz-flex-basis: $width;
  -ms-flex-basis: $width;
  flex-basis: $width;
}
@mixin justify-content($justify) {
  -webkit-justify-content: $justify;
  -moz-justify-content: $justify;
  -ms-justify-content: $justify;
  justify-content: $justify;
  -ms-flex-pack: $justify;
}
@mixin align-content($align) {
  -webkit-align-content: $align;
  -moz-align-content: $align;
  -ms-align-content: $align;
  align-content: $align;
}
@mixin align-items($align) {
  -webkit-align-items: $align;
  -moz-align-items: $align;
  -ms-align-items: $align;
  -ms-flex-align: $align;
  align-items: $align;
}
@mixin align-self($align) {
  -webkit-align-self: $align;
  -moz-align-self: $align;
  -ms-align-self: $align;
  align-self: $align;
}
@mixin flexCenter(){
  @include flexbox();
  @include align-items(center);
  @include justify-content(center);
}


//transform
@mixin transform($transforms) {
  -webkit-transform: $transforms;
  -moz-transform: $transforms;
  -ms-transform: $transforms;
  transform: $transforms;
}
@mixin rotate($deg) {
  @include transform(rotate(#{$deg}deg));
}
@mixin scale($scale) {
  @include transform(scale($scale));
}
@mixin translate($x, $y) {
  @include transform(translate($x, $y));
}
@mixin skew($x, $y) {
  @include transform(skew(#{$x}deg, #{$y}deg));
}
@mixin transform-origin ($origin) {
  -webkit-transform-origin: $origin;
  -moz-transform-origin: $origin;
  -ms-transform-origin: $origin;
  transform-origin: $origin;
}

//transition
@mixin transition($transition...) {
  -moz-transition: $transition;
  -o-transition: $transition;
  -webkit-transition: $transition;
  transition: $transition;
}
@mixin transition-property($property...) {
  -moz-transition-property: $property;
  -o-transition-property: $property;
  -webkit-transition-property: $property;
  transition-property: $property;
}
@mixin transition-duration($duration...) {
  -moz-transition-property: $duration;
  -o-transition-property: $duration;
  -webkit-transition-property: $duration;
  transition-property: $duration;
}
@mixin transition-timing-function($timing...) {
  -moz-transition-timing-function: $timing;
  -o-transition-timing-function: $timing;
  -webkit-transition-timing-function: $timing;
  transition-timing-function: $timing;
}
@mixin transition-delay($delay...) {
  -moz-transition-delay: $delay;
  -o-transition-delay: $delay;
  -webkit-transition-delay: $delay;
  transition-delay: $delay;
}

//animation
@mixin animation-name($name...) {
  -o-animation-name: $name;
  -moz-animation-name: $name;
  -webkit-animation-name: $name;
  animation-name: $name;
}
@mixin animation-duration($duration...) {
  -o-animation-duration: $duration;
  -moz-animation-duration: $duration;
  -webkit-animation-duration: $duration;
  animation-duration: $duration;
}
@mixin animation-delay($delay...) {
  -o-animation-delay: $delay;
  -moz-animation-delay: $delay;
  -webkit-animation-delay: $delay;
  animation-delay: $delay;
}
@mixin animation-timing-function($timing...) {
  -o-animation-timing-function: $timing;
  -moz-animation-timing-function: $timing;
  -webkit-animation-timing-function: $timing;
  animation-timing-function: $timing;
}
@mixin animation-iteration-count($count...) {
  -o-animation-iteration-count: $count;
  -moz-animation-iteration-count: $count;
  -webkit-animation-iteration-count: $count;
  animation-iteration-count: $count;
}
@mixin animation-direction($direction...) {
  -o-animation-direction: $direction;
  -moz-animation-direction: $direction;
  -webkit-animation-direction: $direction;
  animation-direction: $direction;
}
@mixin animation-fill-mode($fill...) {
  -o-animation-fill-mode: $fill;
  -moz-animation-fill-mode: $fill;
  -webkit-animation-fill-mode: $fill;
  animation-fill-mode: $fill;
}
@mixin animation-play-state($state...) {
  -o-animation-play-state: $state;
  -moz-animation-play-state: $state;
  -webkit-animation-play-state: $state;
  animation-play-state: $state;
}
@mixin animation($animation...) {
  -o-animation: $animation;
  -moz-animation: $animation;
  -webkit-animation: $animation;
  animation: $animation;
}

//triangle generator
@mixin triangle($direction, $border, $color){
  width: 0;
  height: 0;
  border-style: solid;
  border-width: $border;
  @if $direction == 'top'{
    border-color: transparent transparent $color transparent;
  } @else if $direction == 'bottom'{
    border-color: $color transparent transparent transparent;
  } @else if $direction == 'left'{
    border-color: transparent $color transparent transparent;
  } @else if $direction == 'right'{
    border-color: transparent transparent transparent $color;
  } @else if $direction == 'top left'{
    border-color: $color transparent transparent transparent;
  } @else if $direction == 'top right'{
    border-color: transparent $color transparent transparent;
  } @else if $direction == 'bottom left'{
    border-color: transparent transparent transparent $color;
  } @else if $direction == 'bottom right'{
    border-color: transparent transparent $color transparent;
  }
}

//line clamp // giá»i háº¡n sá» dÃ²ng text hiá»n thá»
@mixin line-clamp($number){
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: $number;
}

//filter
@mixin filter($value) {
  -moz-filter: $value;
  -o-filter: $value;
  filter: $value;
}

//placeholder
@mixin placeholder() {
  &::-webkit-input-placeholder {@content}
  &:-moz-placeholder           {@content}
  &::-moz-placeholder          {@content}
  &:-ms-input-placeholder      {@content}
}


// background Instagram
@mixin bg-instagram {
  background: #f09433;
  background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
  background: -webkit-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);
  background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
}
