//header__nav
&__nav {
    $bg-menu-dropdown: $color-main-light;

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    //header nav desktop
    @media (min-width: 1475px) {
        @include flexbox();
        @include justify-content(space-between);
        //menu level 1
        > ul {
            @include flexbox();
            @include justify-content(space-between);

            > li {
                position: relative;
                display: inline-block;
                color: #fff;
                &:hover{
                    > a {
                        color: $color-hover;
                    }

                    > ul {
                        display: block;
                        visibility: visible;
                        opacity: 1;
                        @include translate(0, 0)
                    }

                    //active menu
                    position: relative;
                }

                &.active{
                    > a{
                       color: $color-main-light;
                    }
                    &:after{
                        content: '';
                        width: 100%;
                        height: 2px;
                        bottom: 3px;
                        left: 0;
                        background: $color-main-light;
                        position: absolute;
                    }
                    &:before{
                        content: '';
                        width: 100%;
                        height: 2px;
                        top: 3px;
                        left: 0;
                        background: $color-main-light;
                        position: absolute;
                    }
                }

                &.has-child {
                    > a {
                        position: relative;

                        &:before {
                            content: '';
                            @include triangle(top, 5px 6px 8px, $bg-menu-dropdown);
                            position: absolute;
                            bottom: 0;
                            left: 50%;
                            @include transition(all ease-out .1s);
                            @include translate(-50%, 30px);
                            visibility: hidden;
                            opacity: 0;
                        }
                        &:after{
                            content: '\f0d7';
                            font-family: $font-awesome;
                            display: inline-block;
                            margin-left: 2px;
                            font-weight: bold;
                        }
                    }

                    &:hover {
                        > a {
                            &:before {
                                @include translate(-50%, 0);
                                visibility: visible;
                                opacity: 1;
                            }
                        }
                    }
                }

                &.view-more {
                    > a {
                        color: $color-main;
                        text-decoration: underline;

                        &:hover {
                            color: $color-hover;
                        }
                    }
                }

                > a {
                    display: block;
                    padding: 12px 10px;
                    font-size: 13px;
                    text-transform: uppercase;
                    font-weight: 400;
                    text-align: center;

                    i {
                        font-size: 24px;
                    }

                    span {
                        display: block;
                        margin-top: 5px;
                    }

                    @media (min-width: 1675px) {
                        font-size: 16px;
                    }
                }

                //menu level 2 - dropdown level 1
                > ul {
                    position: absolute;
                    top: 100%;
                    left: 0;
                    white-space: normal;
                    width: 220px;
                    text-align: left;
                    @include transition(all ease-out .1s);
                    @include box-shadow(0 10px 15px -12px #ccc);
                    @include translate(0, 30px);
                    visibility: hidden;
                    opacity: 0;
                    display: none;
                    z-index: 100;

                    //menu level *n - dropdown level *n
                    li {
                        position: relative;
                        color: $color-hover;
                        &:hover, &.active {
                            > ul {
                                visibility: visible;
                                opacity: 1;
                                @include translate(0, 0);
                            }

                            > a {
                                background: $color-main;
                                color: #fff;
                            }
                        }

                        &:not(:first-child) {
                            border-top: 1px solid #f3f3f3;
                        }

                        > a {
                            display: block;
                            padding: 1rem;
                            background: $bg-menu-dropdown;
                        }

                        ul {
                            position: absolute;
                            left: 100%;
                            top: 0;
                            width: 220px;
                            padding-left: 3px;
                            @include transition(all ease-out .3s);
                            @include box-shadow(0 10px 15px -12px #ccc);
                            @include translate(30px, 0);
                            visibility: hidden;
                            opacity: 0;
                        }

                        li {
                            &:first-child {
                                &:before {
                                    content: '';
                                    @include triangle(left, 5px 8px 5px, $bg-menu-dropdown);
                                    position: absolute;
                                    top: 18px;
                                    left: -15px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    //header nav mobile
    @media (max-width: 1474px) {
        margin-right: 0;
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        background: rgba(#000, 0.5);
        visibility: hidden;
        opacity: 0;
        z-index: 100;
        height: 100vh;
        overflow: auto;
        @include transition(all linear .2s);

        &::-webkit-scrollbar {
            width: 0;
            height: 0;
        }
        &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            width: 270px;
            background: #fff;
            visibility: hidden;
            @include transition(all ease-out .3s);
            @include translate(-100%, 0);
        }

        &.show-mobile {
            opacity: 1;
            visibility: visible;

            > ul, &:before {
                visibility: visible;
                @include translate(0, 0);
            }
        }
        .button-nav-child {
            position: absolute;
            height: 46px;
            width: 30px;
            line-height: 46px;
            text-align: center;
            right: 0;
            top: 0;
            cursor: pointer;
        }
        > ul {
            width: 100%;
            max-width: 270px;
            background: #fff;
            overflow: auto;
            visibility: hidden;
            @include translate(-100%, 0);
            @include transition(all ease-out .3s);
            padding-bottom: 120px;

            a {
                display: block;
                padding: 1rem 30px 1rem 1rem;

                i:first-child {
                    width: 20px;
                }
            }

            > li {
                border-color: #f3f3f3 !important;
                font-weight: 600;

                &.view-more {
                    > a {
                        color: $color-main;
                        text-decoration: underline;

                        &:hover {
                            color: $color-hover;
                        }
                    }
                }

                &.active{
                    background: $color-main-light;
                    > a{
                        color: $color-main-dark;
                    }
                    li{
                        border-color: rgba(#fff, 0.7);
                        border-top: none;
                        &:before{
                            color: rgba(#fff, 0.7);
                        }
                    }
                }
            }

            li {
                position: relative;
                border-top: 1px solid rgba(#fff, 0.7);

                ul {
                    padding-left: 1rem;

                    li {
                        border-left: 1px dashed #ddd;

                        &:before {
                            content: '--';
                            position: absolute;
                            left: 0;
                            top: 16px;
                            font-size: 10px;
                            color: #ddd;
                        }
                    }
                }
            }
        }
    }
}

//button open header nav mobile
.open-header-nav {
    padding: 0 15px 0 0;
    border: none;
    color: #fff;
    background: none;
    font-size: 24px;
    float: left;
    line-height: 1;
}

//button close header nav mobile
.close-header-nav {
    padding: 0;
    border: none;
    color: #fff;
    background: none;
    font-size: 24px;
    position: fixed;
    left: 280px;
}

//Responsive Header
@media (min-width: 1475px) {
    .open-header-nav,
    .close-header-nav {
        display: none;
    }
}
