//font-size
.font-16{
  font-size: 16px;
}
.font-18{
  font-size: 18px;
}

//min-width
.min-w-100{
    min-width: 100px;
}
.min-w-150{
    min-width: 150px;
}
.min-w-200{
    min-width: 200px;
}
.min-w-250{
    min-width: 250px;
}
.min-w-300{
    min-width: 300px;
}

//btn
.btn-default{
  background: $color-hover;
  color: #fff;
  &:hover{
    color: #fff;
    background: rgba($color-hover, 0.85);
  }
}

//text format
.text-format{
  line-height: 1.5;
  *{
    max-width: 100%;
  }
  h1,h2,h3,h4,h5,h6{
    color: #333;
    font-weight: bold;
    margin-bottom: 10px;
    &:not(:first-child){
      padding-top: 10px;
    }
  }
  blockquote{
    color: #868686;
    font-style: italic;
    padding-left: 15px;
    border-left: 2px solid #eaeaea;
    &:last-child{
      margin-bottom: 0;
    }
  }
  ul,ol{
    padding-left: 20px;
    text-align: left;
    li{
      margin-bottom: 5px;
      &:last-child{
        margin-bottom: 0;
      }
    }
  }
  table{
    width: 100%;
    margin-bottom: 10px;
    td{
      vertical-align: top;
    }
  }
  p,ul,ol,table{
    &:last-child {
      margin-bottom: 0;
    }
  }
  img{
    height: auto;
  }
}


//iframe video responsive
.video-embed {
  overflow: hidden;
  position: relative;
  width:100%;
  &::after {
    padding-top: 56.25%;
    display: block;
    content: '';
  }
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

@import "color-base";

//button default
.btn-cancel{
    &, &.btn-default{
        color: #fff;
        background-color: #6c757d;
        border-color: #6c757d;
        &:hover, &:focus{
            background-color: #545b62;
            border-color: #4e555b;
        }
        &:focus{
            @include box-shadow(0 0 0 0.2rem rgba(#828a91, 0.5));
        }
    }
}


.required > label:after {
    content: ' *';
    color: #C3232D;
}
.checkbox > label{
    margin-bottom: 0;
    input[type=checkbox]{
        float: left;
        margin: 5px 5px 0 0;
    }
}
